
<i18n locale="th" lang="yaml" >
page.section.service_center : "ศูนย์บริการ"
page.section.head_office : "สำนักงาน"
page.section.inactive : "ยกเลิกชั่วคราว"
page.section.central : "ศูนย์บริการกลาง"
</i18n>

<template>
	<div>
		<template v-for="(list, key) of companyList">
			<template v-if="companyList[key].length > 0">
				<div :key="key + '_title'" class="page-section-title">
					{{ $t('page.section.' + key) }}
				</div>
				<MyCardList :key="key" :num-skeletons="3" :empty="list.length === 0" class="company-list" :loading="loading">
					<CompanyLiteCard slot="skeleton" :bordered="true" :loading="true" />
					<CompanyLiteCard
						v-for="company of list"
						:key="company.id"
						:company="company"
						:bordered="true"
						:show-all-tags="true"
						:selectable="selectable"
						:is-service-center="isServiceCenter"
						:clickable="clickable"
						@id="companyIdChange"/>
				</MyCardList>
			</template>
		</template>
	</div>
</template>

<script>
import MyCardList from '@components/common/MyCardList.vue'
import CompanyLiteCard from '@components/company/CompanyLiteCard.vue'

export default {
	components: {
		MyCardList,
		CompanyLiteCard,
	},
	props: {
		companies: {
			type: Array,
			default: () => [],
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		isServiceCenter: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		canViewInactive() {
			return this.$authorize('updateAll', 'company')
		},
		clickable() {
			return !this.selectable
		},
		companyList() {
			const SORT_FUNC = (a, b) => a.company_type - b.company_type
			const HEAD_OFFICE = [1, 2]
			const SERVICE_CENTER_LIST = [4, 5, 6]
			const list = {
				service_center: this.companies
					.filter((company) => {
						return company.company_status != 'inactive' && SERVICE_CENTER_LIST.includes(company.company_type)
					})
					.sort(SORT_FUNC),

				central: this.companies
					.filter((company) => {
						return (
							company.company_status != 'inactive' &&
							!SERVICE_CENTER_LIST.includes(company.company_type) &&
							!HEAD_OFFICE.includes(company.company_type)
						)
					})
					.sort(SORT_FUNC),

				head_office: this.companies
					.filter((company) => {
						return company.company_status != 'inactive' && HEAD_OFFICE.includes(company.company_type)
					})
					.sort(SORT_FUNC),
			}
			if (this.canViewInactive) {
				const inactiveList = this.companies
					.filter((company) => {
						return company.company_status == 'inactive'
					})
					.sort(SORT_FUNC)
				if (inactiveList.length > 0) {
					list.inactive = inactiveList
				}
			}
			return list
		},
	},
	methods: {
		companyIdChange(id) {
			this.$emit('idChange', id)
		},
	},
}
</script>

<style lang="less" scoped>
.company-list::v-deep {
	margin-bottom: 32px;
	.company-card {
		width: 400px;
		.mobile & {
			width: 100%;
		}
	}
}
</style>
